import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';
import * as firebase from 'firebase';
import 'firebase/firestore';
import './new-report.scss';
import ReactTooltip from 'react-tooltip';

export default class AdminNewReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      display_name: '',
      powerbi_id: '',
      powerbi_groupid: '',
      creatingReport: false
    }
  }

  clearinputs = () => {
    $('input').each(function () {
      $(this).val('');
    });
  }



  createReport = async () => {
    this.setState({ creatingReport: true })
    var _this = this;

    var inputsValid = true;

    if (this.state.powerbi_id.length != 36 || this.state.powerbi_groupid.length != 36) {
      toast.error('Invalid id length!');
      this.setState({ creatingReport: false });
      return;
    }

    $('input').each(function () {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });

    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ creatingReport: false });
      return;
    }

    var report = {
      display_name: this.state.display_name,
      report_id: this.state.powerbi_id,
      group_id: this.state.powerbi_groupid
    }

    try {
      var db = firebase.firestore();
      await db.collection('reports').add(report)
      toast.success('Report added!');
    }
    catch(e) {
      console.log(e);
      toast.error('Erro adding report');
    }
    this.setState({ creatingReport: false });

  }


  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.createReport();
    }
  }
  render() {
    return (
      <div className="admin-new-report">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Create New Report</div>
            <div className="subtitle">Fill out the form to create a new report</div>

            <div className="form-group">
              <Label data-tip='display' data-for='display' className="input-title">Display Name</Label>
              <ReactTooltip id='display'>
                <p>Name you want the report to appear as on the website</p>
              </ReactTooltip>
              <Input type="text"
                className="text-input"
                placeholder="Display Name"
                onChange={(event) => { this.setState({ display_name: event.target.value }) }}
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <div className="form-group">
              <Label data-tip='powerid' data-for='powerid' className="input-title">PowerBI Report ID</Label>
              <ReactTooltip id='powerid'>
                <p>Report ID from PowerBI. Value is in the url of the report.<br />
                  EX. https://app.powerbi.com/groups/groupid/reports/<b>reportid</b>/ReportSection
                 </p>
              </ReactTooltip>
              <Input type="text"
                className="text-input"
                placeholder="Report ID"
                onChange={(event) => { this.setState({ powerbi_id: event.target.value }) }}
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <div className="form-group">
              <Label data-tip='powergid' data-for='powergid' className="input-title">PowerBI GroupID</Label>
              <ReactTooltip id='powergid'>
                <p>Group ID from PowerBI. Value is in the url of the report.<br />
                  EX. https://app.powerbi.com/groups/<b>groupid</b>/reports/reportid/ReportSection
                 </p>
              </ReactTooltip>
              <Input type="text"
                className="text-input"
                placeholder="Group ID"
                onChange={(event) => { this.setState({ powerbi_groupid: event.target.value }) }}
                onKeyDown={this.handleKeyPress}
              />
            </div>

            <Button className="create-report-button"
              onClick={this.createReport}
              disabled={this.state.creatingReport}
            >
              {this.state.creatingReport ?
                <div>
                  <b>Create New Report     </b>
                  <i className="fas fa-spinner fa-spin"></i>
                </div>
                :
                <div>
                  <b>Create New Report</b>
                </div>
              }
            </Button>

          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    );
  }
}