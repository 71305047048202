import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import * as firebase from 'firebase';

// pages
import Login from './pages/login/login';
import NewPassword from './pages/new-password/new-password';
import ForgotPassword from './pages/forgot-password/forgot-password';
import Home from './pages/home/home';
import AdminNewUser from './pages/admin/new-user/new-user';
import AdminEditUser from './pages/admin/edit-user/edit-user';
import AdminUpdateReport from './pages/admin/update-report/update-report';
import AdminNewReport from './pages/admin/new-report/new-report';
import NotFound from './pages/not-found/not-found';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCTWl36QGqyZPgkupv6h6g8dMC9keDMC0s",
  authDomain: "concentric-powerbi-web.firebaseapp.com",
  databaseURL: "https://concentric-powerbi-web.firebaseio.com",
  projectId: "concentric-powerbi-web",
  storageBucket: "concentric-powerbi-web.appspot.com",
  messagingSenderId: "403842264692",
  appId: "1:403842264692:web:635f0460e76df9edff2b21",
  measurementId: "G-6XT20G1C3G"
};
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    sessionStorage.setItem('uid', user.uid);
  } else {
    sessionStorage.clear();
  }
});

const createHistory = require("history").createBrowserHistory;
const history = createHistory()

// session for debugging
//sessionStorage.clear();
//sessionStorage.setItem('admin', 'true');
//sessionStorage.setItem('uid', '1');

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    sessionStorage.getItem('uid')
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    sessionStorage.getItem('admin')
      ? <Component {...props} />
      : <Redirect to='/home' />
  )} />
)

const LocalAdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    sessionStorage.getItem('local_admin') || sessionStorage.getItem('admin')
      ? <Component {...props} />
      : <Redirect to='/home' />
  )} />
)

if (window.location.pathname === '/') {
  if (sessionStorage.getItem('uid')) {
    window.location.replace('/home');
  } else {
    window.location.replace('/login');
  }
}

class App extends Component {

  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL} history={history}>
        <div className="App">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <PrivateRoute exact path="/new-password" component={NewPassword} />
                <PrivateRoute exact path="/home" component={Home} />
                <LocalAdminRoute exact path="/admin/new-user" component={AdminNewUser} />
                <LocalAdminRoute exact path="/admin/edit-user" component={AdminEditUser} />
                <AdminRoute exact path="/admin/new-user" component={AdminNewUser} />
                <AdminRoute exact path="/admin/edit-report" component={AdminUpdateReport} />
                <AdminRoute exact path="/admin/new-report" component={AdminNewReport} />
                <Redirect from="*" to="/home" />
            </Switch>
        </div>
      </BrowserRouter>
    );
  }
  
}

export default App;