import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './header.scss';
import { NavLink } from 'react-router-dom';

import * as firebase from 'firebase';


class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.closeIfOpen = this.closeIfOpen.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  // Closes the mobile navbar if it is expanded when
  // changing pages
  closeIfOpen() {
    if (this.state.isOpen === true) {
      this.setState({
        isOpen: false
      });
    }
  }

  logout = async () => {
    firebase.auth().signOut().then(() => {
      sessionStorage.clear();
      this.props.history.push('/login');
    });
  }

  render() {
    var _this = this;
    function NavRender(props) {

      let admin = 
      <Dropdown nav isOpen={_this.state.adminOpen} toggle={() => {_this.setState(prevState => ({adminOpen: !prevState.adminOpen}))}}>
        <DropdownToggle className="dropdown-link" nav caret>
          Admin
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => {_this.props.history.push('/admin/new-user')}}>Add User</DropdownItem>
          <DropdownItem divider />
            <DropdownItem onClick={() => { _this.props.history.push('/admin/edit-user') }}>Edit User</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => { _this.props.history.push('/admin/new-report') }}>Add Report</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => { _this.props.history.push('/admin/edit-report') }}>Edit Report</DropdownItem>
        </DropdownMenu>
        </Dropdown>

      let localadmin = 
          <Dropdown nav isOpen={_this.state.adminOpen} toggle={() => { _this.setState(prevState => ({ adminOpen: !prevState.adminOpen })) }}>
            <DropdownToggle className="dropdown-link" nav caret>
              Admin
            </DropdownToggle>
            <DropdownMenu>
            <DropdownItem onClick={() => { _this.props.history.push('/admin/new-user') }}>Add User</DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={() => { _this.props.history.push('/admin/edit-user') }}>Edit User</DropdownItem>
          </DropdownMenu>
        </Dropdown>

      if (!sessionStorage.getItem('admin')) {
        admin = null;
      }

      if (!sessionStorage.getItem('local_admin')) {
        localadmin = null;
      }

      if (sessionStorage.getItem('uid') && window.location.pathname !== '/login') {
        return (
          <div className="ml-auto">
            <NavbarToggler onClick={_this.toggle} />
            <Collapse isOpen={_this.state.isOpen} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink to="/home" onClick={_this.closeIfOpen} style={{ color: '#0099d5' }}>Home</NavLink>
                </NavItem>
                {admin}
                {localadmin}
                <Dropdown nav isOpen={_this.state.accountOpen} toggle={() => {_this.setState(prevState => ({accountOpen: !prevState.accountOpen}))}}>
                  <DropdownToggle className="dropdown-link" nav caret>
                    Account
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem onClick={() => {_this.props.history.push('/new-password')}}>Change Password</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => _this.logout()}>Log Out</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </Nav>
            </Collapse>
          </div>
        );
      }
      else {
        return (
          <Nav className="ml-auto" navbar>
            <span>
              <NavbarBrand href="/"></NavbarBrand>
            </span>
          </Nav>
        );
      }
    }

    return (
      <div>
        <Navbar className="bb-navbar navbar nav-header" light expand="md">
          <span>
            <NavbarBrand href="/"><img src={require("../../assets/concentric.jpg")} alt="RAAMS" className="logo-img"/></NavbarBrand>
          </span>
          <NavRender />
        </Navbar>
      </div>
    );
  }
}

export default Header;
